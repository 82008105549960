import {
  NotificationTypes,
  RecurringNotificationTypes,
} from "@shared/resources/notificationTypes";
import { PushNotificationTypeEnum } from "@shared/types/push-notification/enums/push-notification-type.enum";
import { PushNotificationDto } from "@shared/types/push-notification/push-notification.dto";
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Space,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const requiredFieldRule = {
  required: true,
  whitespace: true,
  message: "",
};

interface IModal {
  open: boolean;
  notification: PushNotificationDto | null;
  onClose: () => void;
  onSave: (data: PushNotificationDto) => void;
  isLoading: boolean;
  isError?: boolean;
}

const ChangeNotificationModal = ({
  open,
  notification,
  onClose,
  onSave,
  isLoading,
  isError,
}: IModal) => {
  const [form] = Form.useForm();
  const [isOneTime, setIsOneTime] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);

  useEffect(() => {
    if (notification) {
      const formData = {
        ...notification,
        dateOfDelivery: notification.dateOfDelivery
          ? dayjs(notification.dateOfDelivery)
          : null,
      };
      form.setFieldsValue(formData);
      handleNotificationTypeChange(notification.type);
    }
  }, [form, notification]);

  const handleNotificationTypeChange = (value: string) => {
    setIsRecurring(value === PushNotificationTypeEnum.RECURRING);
    setIsOneTime(value === PushNotificationTypeEnum.ONE_TIME);
  };

  const handleFinish = (values: PushNotificationDto) => {
    const formattedValues = {
      ...values,
      dateOfDelivery: values.dateOfDelivery
        ? dayjs(values.dateOfDelivery).toDate()
        : null,
      isSent: false,
    };
    onSave(formattedValues);
  };

  return (
    <Modal
      title="Change Notification"
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <Form form={form} requiredMark={false} onFinish={handleFinish}>
        {isError && (
          <Form.Item>
            <Alert
              message="Error"
              description="An error occurred while saving the notification data. Please try again."
              type="error"
              showIcon
            />
          </Form.Item>
        )}

        <Form.Item
          name="title"
          label="Title"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[requiredFieldRule]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="message"
          label="Body"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[requiredFieldRule]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          name="type"
          label="Type"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[requiredFieldRule]}
        >
          <Select
            options={NotificationTypes}
            onChange={handleNotificationTypeChange}
          />
        </Form.Item>

        {(isOneTime || isRecurring) && (
          <Form.Item
            name="dateOfDelivery"
            label={isRecurring ? "Next Delivery Date" : "Date of Delivery"}
            labelCol={{ span: 24, style: { padding: 0 } }}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: "Date of Delivery is required",
              },
            ]}
          >
            <DatePicker
              style={{
                width: "100%",
              }}
              showTime={{
                showHour: true,
                showMinute: true,
                minuteStep: 15,
              }}
              inputReadOnly={true}
              disabledDate={(current) => {
                return current && current < dayjs().startOf("day");
              }}
              disabledTime={(current) => {
                const now = dayjs();
                if (current && current.isSame(now, "day")) {
                  return {
                    disabledHours: () =>
                      Array.from({ length: now.hour() }, (_, i) => i),
                    disabledMinutes: (selectedHour) =>
                      selectedHour === now.hour()
                        ? Array.from({ length: now.minute() }, (_, i) => i)
                        : [],
                  };
                }
                return {};
              }}
            />
          </Form.Item>
        )}

        {isRecurring && (
          <Form.Item
            name="recurringType"
            label="Recurring Type"
            labelCol={{ span: 24, style: { padding: 0 } }}
            style={{ margin: 0 }}
            rules={[requiredFieldRule]}
          >
            <Select options={RecurringNotificationTypes} />
          </Form.Item>
        )}

        <Space
          style={{ justifyContent: "flex-end", width: "100%", marginTop: 15 }}
        >
          <Form.Item>
            <Button type="default" onClick={onClose}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {!isOneTime && !isRecurring ? "Send" : "Save"}
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default ChangeNotificationModal;
