import { appInject, appInjectable } from "@core/di/utils";
import { BaseService } from "@core/services/base";
import { DI_TOKENS } from "@shared/constants/di";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { IUserService } from "@shared/interfaces/user.interface";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";
import { UserListPaginatedDto } from "@shared/types/user/user-list-paginated.dto";
import {
  UpdateUserDraftTokensDto,
  UpdateUserDraftTokensResponseDto,
  UpdateUserDto,
  UserDto,
} from "@shared/types/user/user.dto";
import { UserProviderType } from "@shared/types/user/enum/user-provider-type.enum";

@appInjectable()
export class UserService extends BaseService implements IUserService {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClient = appInject<IHttpClientService>(
    DI_TOKENS.httpClientService,
  );
  private baseURL: string;

  constructor() {
    super();
    this.baseURL = this.configService.baseUrl;
  }

  async update(dto: UpdateUserDto): Promise<UserDto> {
    const { data } = await this.httpClient.patch<UserDto>(
      `/admin/update-user/${dto.id}`,
      {
        ...dto,
      },
      {
        baseURL: this.baseURL,
      },
    );

    return data;
  }

  async getUsersList(dto: {
    page?: number;
    pageSize?: number;
    disabled?: string;
    provider?: UserProviderType;
  }): Promise<UserListPaginatedDto> {
    const { data } = await this.httpClient.get<UserListPaginatedDto>(
      "/user/list",
      {
        params: dto,
        baseURL: this.baseURL,
      },
    );

    return data;
  }

  async reDealCards(dto: { id: string }): Promise<UserDto> {
    const { data } = await this.httpClient.post<UserDto>(
      `/admin/re-deal-cards/${dto.id}`,
      {},
      {
        baseURL: this.baseURL,
      },
    );

    return data;
  }

  async updateDraftTokens(
    dto: UpdateUserDraftTokensDto,
  ): Promise<UpdateUserDraftTokensResponseDto> {
    const { data } =
      await this.httpClient.patch<UpdateUserDraftTokensResponseDto>(
        `/admin/draft-tokens/${dto.id}`,
        dto,
        {
          baseURL: this.baseURL,
        },
      );

    return data;
  }

  async resetUserAccount(dto: { id: string }): Promise<UserDto> {
    const { data } = await this.httpClient.post<UserDto>(
      `/user/reset-data/${dto.id}`,
      {},
      {
        baseURL: this.baseURL,
      },
    );

    return data;
  }
}
